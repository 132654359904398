<template>
  <div class="text-center">
    <v-overlay
      v-model="isLoading"
    >
      <v-card
        color="red"
        dark
      >
        <v-card-text>
          Please wait!
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>


<script>
  export default {
    data: () => ({}),
    props: ['isLoading'],
  }
</script>